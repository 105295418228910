#countdown {
  width: 100%;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  display: inline-block;
  text-align: center;
  border-radius: 20px;
}
#countdown .box {
  height: 120px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding:40px;
  border-right: solid 1px rgba(255, 255, 255, 0.2);
}
#countdown .col-4:last-child .box {
  border-right-color: transparent;
}

#countdown .box p {
  font-size: 50px;
  font-weight: bold;
  margin: 0;
}
#countdown .box .text {
  margin-top: 40px;
  font-size: 32px;
  font-family: sans-serif;
}

@media (max-width: 768px) {
  .container {
    width: 100%;
  }
  h1 {
    font-size: 58px;
  }
  #countdown {
    padding: 20px;
  }
}
